<template>
  <div style="height: 100vh;overflow: hidden;">
    <div class="p-tb-10 t-a-c font-s-16 title">购物车
    </div>
    <div style="position: absolute; top: 0.8rem;right: 0.5rem; font-size: 0.8rem" v-if="pay" @click="editCar"> 编辑
    </div>
    <div style="position: absolute; top: 0.8rem;right: 0.5rem; font-size: 0.8rem" v-else @click="finishCar"> 完成
    </div>
    <!-- <div v-if="name === null">
      <van-empty description="未登录" class="empt1 font-s-16">
        <div @click="goLogin">去登陆</div>
      </van-empty>
    </div> -->
    <!-- 登陆状态 -->
    <div style="overflow: auto; height: 100%;">
      <!-- 没有商品时 -->
      <div v-if="carData.length === 0" class="height-100">
        <van-empty description="未添加商品，去购物" class="empt font-s-16">
        </van-empty>
      </div>
      <!-- 有商品时 -->
      <div v-else class="box">
        <!-- 单独的商品 -->
        <div v-for="(item, index) in carData" :key="index" class="goods">

          <van-swipe-cell>
            <div class="flex">
              <div class="p-lr-10">
                <van-checkbox checked-color="#ee0a24" :name="item" v-model="item.check" @change="change1" />
              </div>
              <div class="mr-10"></div>
              <div class="flex width-100">
                <div @click="toview(item.cid)">
                  <van-image class="img" :src="item.coverImage" alt="" fit="cover" />
                </div>
                <div class="p-t-10 p-lr-10 width-100">
                  <div class="name">{{ item.productName }}</div>
                  <div class="p-tb-10 flex jcsb">
                    <div class="font-c-red">
                      {{ item.productPrice }}点
                    </div>
                    <div>
                      <van-stepper v-model="item.productNum" @change="stechange(item)" max="50" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <template #right>
              <div class="del-alsid" @click="delgoods(item, index)">
                删除
              </div>
            </template>
          </van-swipe-cell>
        </div>

        <div class="flex jcsb orderhead">
          <div class="flex">
            <div class="p-lr-10">
              <van-checkbox checked-color="#ee0a24" type="checkbox" @change="checkAll" v-model="checked" />
            </div>
            <div v-if="checked">取消全选</div>
            <div v-else>全选</div>
          </div>
          <div class="t-a-r p-2 width-order">
            <div class="p-tb-10 flex" style="justify-content: space-between;">
              <div>合计:</div>
              <div class="font-c-red width-sum t-a-l">{{ sum }}</div>
              <van-button style="height: 2.5rem; width: 5rem;background-color:#A9CE57;color: white;" @click="goOrder"
                v-if="pay">去结算</van-button>
              <van-button style="height: 2.5rem; width: 5rem;background-color:#A9CE57;color: white;" @click="del"
                v-else>删除</van-button>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <foot-nav></foot-nav>
  </div>
</template>

<script>
import { Checkbox, CheckboxGroup } from "vant";

import FootNav from "../../components/footnav/FootNav.vue";
export default {
  name: "",
  props: {},
  data() {
    return {
      pay: true,
      carData: [],
      result: [],
      value: 1,
      checked: false,
      username: "",
      deleteDate: [],
    };
  },
  components: { FootNav, Checkbox, CheckboxGroup },
  computed: {
    gooodsType: function () {
      const selectedArr = this.carData.filter(item => item.check === true);
      if (selectedArr.length === 1) { // 当前一个选中的
        return selectedArr[0].productFlag
      } else if (selectedArr.length === 0) { // 没有被选中的
        return null
      }
    }
  },
  methods: {
    editCar() {
      this.pay = false
    },
    finishCar() {
      this.pay = true
    },
    // 请求购物车数据
    getCard() {
      this.$api
        .getCard({
          userId: this.userId,
          sourceChannel: "购物车",
        })
        .then((res) => {
          this.carData = res.data
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 前去登陆
    goLogin() {
      this.$router.push("/Login");
    },
    // 全选
    checkAll() {
      // console.log(this.checked);
      this.carData.map((a) => {
        a.check = this.checked;
      });
      // console.log(this.carData);
    },

    // 单独选则
    change1() {
      this.checked = this.carData.every((a) => {
        return a.check === true;
      });
    },
    // 步进器的事件
    stechange(value) {
      // console.log(value);
      this.$api
        .updateNum(value)
        .then((res) => {
          // console.log(res);
        })
        .catch();
    },

    //删除单个
    delgoods(data, index) {
      console.log(this.carData);
      this.$Dialog
        .confirm({
          title: "删除商品",
          message: "是否将该商品删除",
        })
        .then(() => {
          this.deleteDate.length = 0
          this.deleteDate.push(data)
          this.$api
            .deleteShop(this.deleteDate)
            .then((res) => {
              if (res.code == 1) {
            this.$Toast(res.msg);
            return
          }
              // console.log(res);
              // 页面显示的数据
              this.carData.splice(index, 1);
              console.log(this.carData);
              this.$store.commit("setCarNum", this.carData.length);
              localStorage.setItem(
                `${this.username}carNum`,
                this.carData.length
              );
            })
            .catch((err) => {
              // console.log(err);
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 总删除
    del() {
      let ids = [];
      // 获取删除的id
      this.carData.map((a) => {
        if (a.check) {
          ids.push(a);
        }
      });
      // 是否有选中商品
      if (ids.length > 0) {
        this.$Dialog
          .confirm({
            title: "删除商品",
            message: "是否将商品从购物车删除",
          })
          .then(() => {
            // on confirm
            this.$api
              .deleteShop(ids)
              .then((res) => {
                if (res.code == 1) {
                  this.$Toast(res.msg);
                  return
                }
                this.$Toast.success(res.msg);

                // 页面显示的数据
                this.carData = this.carData.filter((a) => {
                  return !a.check;
                });
                // 本地粗存
                this.$store.commit("setCarNum", this.carData.length);
                localStorage.setItem(
                  `${this.username}carNum`,
                  this.carData.length
                );
              })
              .catch((err) => {
                this.$Toast(err.msg);
              });
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.$Toast("未选中商品");
      }
    },
    // 结算
    goOrder() {
      // 先获取选中的商品
      let carDatas = this.carData.filter((a) => {
        return a.check;
      });
      // 有选中商品
      if (carDatas.length > 0) {
        // 本地储存
        localStorage.setItem("carDatas", JSON.stringify(carDatas));
        localStorage.setItem("idDirect", 0);
        this.$router.push("ToOrder");
      } else {
        this.$Toast("无选中商品");
      }
    },
    // 去详情页
    toview(val) {
  
    },
  },
  mounted() {
    localStorage.getItem("userInfo")
      ? (this.userId = JSON.parse(localStorage.getItem("userInfo")).id)
      : "";
    this.getCard();
  },
  computed: {
    name() {
      return this.$store.state.nickname;
    },
    // 计算总价
    sum() {
      let sum = 0;
      this.carData.map((a) => {
        if (a.check) {
          return (sum += a.productNum * a.productPrice);
        }
      });
      if (sum === 0) {
        return sum;
      } else {
        return `${sum.toFixed(2)}点`;
      }
    },
  },
  watch: {},
};
</script>

<style lang='scss' scoped>
.empt {
  transform: translateY(50%);
}

.empt1 {
  transform: translateY(30%);
}

.box {
  padding-bottom: 9rem;
  overflow: auto;

  .van-button--primary {
    margin-right: 15px;
  }

  .width-order {
    width: 50%;
    padding-right: 0.5rem;

    .width-sum {
      width: 45%;
      font-size: 0.8rem;
    }
  }
}

.title {
  background-color: rgb(236, 224, 216);
  height: 1.5rem;
}

.orderhead {
  background-color: white;
  width: 100%;
  position: absolute;
  bottom: 4rem;
  font-size: 0.8rem;
  height: 2.5rem;
}

.goods {
  background-color: #fff;
  margin-bottom: 5px;
  position: relative;

  .goods-no-selected {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: red;
    width: 100%;
  }

  .img {
    width: 5rem;
    height: 4rem;
  }

  .name {
    height: 3rem;
  }

  .del-alsid {
    display: flex;
    align-items: center;
    width: 3rem;
    background-color: red;
    height: 100%;
    color: white;
    justify-content: center;
    font-size: 18px;
  }
}

.van-button {
  height: 1.5rem;
  width: 4rem;
  padding: 0;
}

body::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: none;
}

::-webkit-scrollbar-thumb:active {
  background-color: none;
}</style>